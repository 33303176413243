<script>
module.exports = {
  data() {
    return {
      passengers: "",
      classes: "",
      results: {},
    };
  },
  methods: {
    fetchFlightFootprints() {
      fetch("https://beta3.api.climatiq.io/travel/flights", {
        method: "POST",
        headers: {
          Authorization: "Bearer "+process.env.VUE_APP_APIKEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          legs: [
            {
              from: this.selectedCountry,
              to: this.selectedCountry2,
              passengers: this.passengers,
              class: this.classes,
            },
          ],
        }),
      })
        .then((response) => response.json())
        .then((data) => (this.results = data));
    },
  },
};
</script>
