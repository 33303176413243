<template>
  <div class="container initial">
    <h1 class="brand">
      <span>F</span>light <span>F</span>ootprint
      <img src="./img/world.png" class="icon" />
    </h1>

    <div class="background-search">
      <div class="input-group mb-3">
        <label for="search" class="">From</label>

        <input
          type="search"
          id="search"
          v-model="searchTerm"
          placeholder="Enter the airport"
          class="form-control rounded"
        />
        <div class="input-group mb-1">
          <ul
            v-if="searchCountries.length"
            class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
          >
            <li class="">
              <span
                >Showing {{ searchCountries.length }} of {{ airports.length }} results
              </span>
            </li>
            <li
              v-for="country in searchCountries"
              :key="country.name"
              @click="selectCountry(country.iata_code)"
              class="cursor-pointer hover:bg-gray-100 p-1"
            >{{country.city}}, {{ country.name }}, {{ country.iata_code }}
            </li>
          </ul>
        </div>
        <p v-if="selectedCountry" class="text-lg pt-2 absolute">
          You have selected: <span class="font-semibold"> {{ selectedCountry }}</span>
        </p>
      </div>
      <!-- Destination -->
      <div class="input-group mb-3">
        <label for="search" class="">To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>

        <input
          type="text"
          id="search"
          v-model="searchTerm2"
          placeholder="Enter the airport"
          class="form-control rounded"
        />

        <div class="input-group mb-3">
          <ul
            v-if="SearchCountries2.length"
            class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
          >
            <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
              <span>
                Showing {{ SearchCountries2.length }} of
                {{ airports2.length }} results</span
              >
            </li>
            <li
              v-for="country in SearchCountries2"
              :key="country.name"
              @click="selectCountry2(country.iata_code)"
              class="cursor-pointer hover:bg-gray-100 p-1"
            >
              {{ country.name }} , {{ country.iata_code }}
            </li>
          </ul>
        </div>
        <p v-if="selectedCountry2" class="text-lg pt-2 absolute">
          You have selected: <span class="font-semibold">{{ selectedCountry2 }}</span>
        </p>
      </div>
      <div class="input-group mb-3">
        <label for="search" class="">Passengers</label>
        <input
          id="pass"
          type="number"
          class="form-control rounded"
          placeholder="enter numbers only"
          aria-label="Search"
          aria-describedby="search-addon"
          v-model="passengers"
        />
      </div>
      <div class="classes">
        <div>
          <p>
            Class&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
        <div class="input-group mb-3">
          <select name="class" id="class" class="form-select" v-model="classes">
            <option value="economy">economy</option>
            <option value="business">business</option>
            <option value="first">first</option>
          </select>
        </div>
      </div>
      <div class="input-group">
        <label for="search" class=""
          >Calculate the <span>f</span>ootprint of this <span>f</span>light</label
        >
        <div class="input-group">
          <button
            id="search-button"
            type="button"
            class="btn btn-light"
            @click="fetchFlightFootprints"
          >
            search
          </button>
        </div>
      </div>
    </div>

    <!-- Results -->
    <div class="container">
      <div class="background-search" v-if="results.co2e > 1">
        <div class="results typewriter">
          <h1>Flight <span>CO</span>2<span>E</span> emissions:</h1>
          <h3>{{ results.co2e.toFixed(2) }} (kg)</h3>
          <h2><span>CO</span>2<span>E</span> emissions per person:</h2>
          <h3>{{ (results.co2e/ this.passengers).toFixed(2) }} (kg)</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import airports2 from "../../data/airports.json";
import airports from "../../data/airports.json";
import { ref, computed } from "vue";
export default {
  mixins: [require("../client_API/clientAPI.vue")],
  setup() {
    let searchTerm2 = ref("");
    let searchTerm = ref("");
    const SearchCountries2 = computed(() => {
      if (searchTerm2.value === "") {
        return [];
      }
      let matches = 0;
      return airports2.filter((country) => {
        if (
          country.name.toLowerCase().includes(searchTerm2.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return country;
        }
      });
    });
    const searchCountries = computed(() => {
      if (searchTerm.value === "") {
        return [];
      }
      let matches = 0;
      return airports.filter((country) => {
        if (
          country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return country;
        }
      });
    });
    const selectCountry2 = (country) => {
      selectedCountry2.value = country;
      searchTerm2.value = "";
    };
    const selectCountry = (country) => {
      selectedCountry.value = country;
      searchTerm.value = "";
    };
    let selectedCountry2 = ref("");
    let selectedCountry = ref("");
    return {
      airports2,
      searchTerm2,
      SearchCountries2,
      selectCountry2,
      selectedCountry2,
      airports,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
    };
  },
};
</script>

<style>
@import "./css/styles.css";
</style>
